/*=========================================================================================
  File Name: analyticsData.vue
  Description: Data shown by charts
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {

  revenueComparisonLine: {

    chartOptions: {
      chart: {
        toolbar: { show: true },
        dropShadow: {
          enabled: true,
          top: 5,
          left: 0,
          blur: 4,
          opacity: 0.20,
        },
      },
      stroke: {
        curve: 'smooth',
        dashArray: [0, 2, 8, 16],
        width: [8, 4, 2, 1],
      },
      grid: {
        borderColor: '#e7e7e7',
      },
      legend: {
        show: true,
      },
      colors: ['#F97794', 'red','#b8c2cc','orange'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          inverseColors: false,
          gradientToColors: ['#7367F0', 'red','#b8c2cc','orange'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          style: {
            cssClass: 'text-grey fill-current',
          },
        },
        axisTicks: {
          show: false,
        },
        categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          style: {
            cssClass: 'text-grey fill-current',
          },
          formatter(val) {
            return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
          },
        },
      },
      tooltip: {
        x: { show: false },
      },
    },
  },
  revenueComparisonLineTrimestre: {

    chartOptions: {
      chart: {
        toolbar: { show: true },
        dropShadow: {
          enabled: true,
          top: 5,
          left: 0,
          blur: 4,
          opacity: 0.20,
        },
      },
      stroke: {
        curve: 'smooth',
        dashArray: [0, 2, 8, 16],
        width: [8, 4, 2, 1],
      },
      grid: {
        borderColor: '#e7e7e7',
      },
      legend: {
        show: true,
      },
      colors: ['#F97794', 'red', '#b8c2cc', 'orange'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          inverseColors: false,
          gradientToColors: ['#7367F0', 'red', '#b8c2cc', 'orange'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          style: {
            cssClass: 'text-grey fill-current',
          },
        },
        axisTicks: {
          show: false,
        },
        categories: ['01', '02', '03'],
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          style: {
            cssClass: 'text-grey fill-current',
          },
          formatter(val) {
            return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
          },
        },
      },
      tooltip: {
        x: { show: false },
      },
    },
  },

  // RADIAL BAR
  goalOverviewRadialBar: {

    chartOptions: {
      plotOptions: {
        radialBar: {
          size: 130,
          startAngle: -150,
          endAngle: 150,

          hollow: {
            size: '70%',
          },
          track: {
            background: 'red',
            strokeWidth: '50%',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 18,
              color: 'red',
              fontSize: '4rem',
            },
          },
        },
      },
      colors: ['green'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['red'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      chart: {
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1
        }
      }
    }
  },
  // Customers
  customersPie: {
    chartOptions: {
      id: 'export',
      dataLabels: {
        enabled: true,
        offset: 30,
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {

          let perc=parseFloat(value).toFixed(2)

          let euroFR = Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
        })
          return w.config.labels[seriesIndex] + ":  " +perc + "% : " + euroFR.format(w.config.series[seriesIndex])
        }
      },
      legend:
        {
          show: true,
          position: 'bottom',
        },
      chart: {
        type: 'pie',
        offsetY: 0,
        dropShadow: {
          enabled: false,
          blur: 5,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        }
      },
      stroke: {
        width: 5,
      },
     
  
    }
  },
  // Customers
  productsSalesChart: {
   
    chartOptions: {
      
      labels: ['SMARTUP','TRAVEL','SCHOME-ST','SCHOME-XL','SCMIXTE-ST','SCMIXTE-XL','SCCITY-ST','SCCITY-XL','SCEVO-DP','SCEVO-ST','SCEVO2-DP','SCEVO2-ST','YP2606','YP2610','YP10051','YP2600'],
      id:'product-sales',
    
      legend: { 
        show: true,
        position: 'bottom',
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        
        style: {
          colors: ['#fff']
        },
        formatter: function(val, { seriesIndex, w }) {
          
          return w.globals.labels[seriesIndex] + ":  " + w.globals.series[seriesIndex]
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
     /* colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
      '#f48024', '#69d2e7'],*/
      
      
       
     
      }
    }

}
