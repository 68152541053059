<template>
  <b-card
    no-body
  >
    <b-card-header class="mb-2">
      <b-card-title>CA du mois</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-row class="mb-2">
        <b-col
          sm="7"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1
            v-if="goalOverview >= 90"
            class="font-weight-bolder font-large-2 text-success"
          >
            {{ revenueComparisonLine.analyticsData.thisMonth.toLocaleString() }}<sup class="text-base mr-1">€</sup>
          </h1>

          <h1
            v-else
            class="font-weight-bolder text-danger"
          >
            {{ revenueComparisonLine.analyticsData.thisMonth.toLocaleString() }}<sup class="text-base mr-1">€</sup>
          </h1>

          <small>M</small>
        </b-col>
        <b-col
          sm="5"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h3 class="font-weight-bolder mt-2 mb-0">
            {{ revenueComparisonLine.analyticsData.lastMonth.toLocaleString() }}<sup class="text-base mr-1">€</sup>
          </h3>
          <small>M-1</small>
          <h3 class="mt-2 mb-0">
            {{ revenueComparisonLine.analyticsData.lastYear.toLocaleString() }}<sup class="text-base mr-1">€</sup>
          </h3>
          <small>N-1</small>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          sm="12"
        >
          <vue-apex-charts
            type="line"
            height="300"
            :options="chartOptions"
            :series="revenueComparisonLine.series"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line no-unused-vars
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    revenueComparisonLine: Object,
    // eslint-disable-next-line vue/require-default-prop
    goalOverview: Number,
    // eslint-disable-next-line vue/require-default-prop
    chartOptions: Object,

  },
}
</script>
