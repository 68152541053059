<template>
  <b-card
    v-if="customersData"
    no-body
  >
    <b-card-header class="mb-2">
      <b-card-title>Ventes par secteurs</b-card-title>
    </b-card-header>

    <b-card-body>

      <b-row>
        <b-col
          sm="12"
        >
          <vue-apex-charts
            type="donut"
            :height="heightChart"
            class="mt-10 mb-10"
            :options="analyticsData.customersPie.chartOptions"
            :series="customersData.series"
          />

        </b-col>
      </b-row>

    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line no-unused-vars
import { $themeColors } from '@themeConfig'
import analyticsData from '@/views/dashboard/analyticsData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    customersData: Object,
    // eslint-disable-next-line vue/require-default-prop
    heightChart: Number,
  },
  data() {
    return {
      analyticsData,
    }
  },
}
</script>
