<template>
  <b-card

    no-body
  >
    <b-card-header>
      <h4 class="mb-0">
        {{ title }}
      </h4>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="analyticsData.goalOverviewRadialBar.chartOptions"
      :series="goalOverview"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Projection J
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ projection.toLocaleString() }}<sup class="text-base mr-1">€</sup>
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Objectif
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ goal.toLocaleString() }}<sup class="text-base mr-1">€</sup>
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import analyticsData from '@/views/dashboard/analyticsData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    goal: Number,
    // eslint-disable-next-line vue/require-default-prop
    projection: Number,
    // eslint-disable-next-line vue/require-default-prop
    goalOverview: Array,
    // eslint-disable-next-line vue/require-default-prop
    title: String,
  },
  data() {
    return {
      analyticsData,
    }
  },
}
</script>
